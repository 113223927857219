import React from 'react'
import ShedulerDropdown from "../../Dropdown/Dropdown";
import { GoClock } from "react-icons/go";
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const RejectedCard = ({item}) => {
  return (
    <>
    {/* {
    props.rejecteddata.map(item => (item.Status_Val=="reject"|| item.Status_Val=="reschedule") ? ( */}
    <div className="col-md-12 col-lg-4">
    <div className="card card-conatiner card-border-rejected-meeting">
      <div className="card-head scheduler-card justify-content-between">
      {isMobile?<h3 className='text-orange'>{moment(item.RequestStart_DtTm).format('D MMM')}</h3>: <h2 className='text-orange'>{moment(item.RequestStart_DtTm).format('D MMM')}</h2>}

        {/* <h2 className='text-orange'>{moment(item.RequestStart_DtTm).format('D MMM')}</h2> */}
        {/* <ShedulerDropdown /> */}
      </div>
      <div className="card-body">
        <h4>{item.Subject_Val}</h4>
        <div className="card-body-head  mb-3">
          <GoClock />
          <span className="">Duration : {moment.duration(((moment(item.RequestedEnd_DtTm).diff(item.RequestStart_DtTm))/1000), "seconds").format("h [hrs], m [min]")}</span>
        </div>
        <div className="card-body-text mb-3">
            {item.Description_Val===""?"Description":item.Description_Val}
        </div>
        {
          item.ProposedStart_DtTm?
          <div>
        Proposed time
        {
          moment(item.ProposedStart_DtTm).format("D MMM")==moment(item.ProposedEnd_DtTm).format("D MMM")?
          <div>
          {moment(item.ProposedStart_DtTm).format("D MMM h:mm a")} - {moment(item.ProposedEnd_DtTm).format("h:mm a")}
          </div>:
           <div>
           {moment(item.ProposedStart_DtTm).format("D MMM h:mm a")} - {moment(item.ProposedEnd_DtTm).format("D MMM h:mm a")}
           </div>
        }
       
        </div>:null
        }
        
        <div className="card-footer">
        <p className="text-orange text-rejected">Rejected by REP</p>
        </div>
        {
          item.RejectReason_Val?
          <div>
          Reason : {item.RejectReason_Val}
        </div>:null
        }
      </div>
    </div>
  </div>
     {/* ):null) } */}
     </>
  )
}
export default RejectedCard;
